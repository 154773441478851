import React from 'react'

export default function Youtube({ title, src }) {
  return (
    <div>
      <iframe
        title={title}
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen=""
        className="aspect-video w-full"
      />
    </div>
  )
}
