import React from 'react'
import { graphql } from 'gatsby'

import WorkContainer from '../../components/WorkContainer'
import Layout from '../../components/Layout'
import { ProjectTitle, EnglishOnly } from '../../components/Text'
import Metadata from '../../components/Metadata'
import Youtube from '../../components/Youtube'
import CustomerLogo from '../../components/CustomerLogo'
import ProjectImage from '../../components/ProjectImage'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function CellExpress({ data }) {
  const { t } = useTranslation()

  const project = data.projectsJson

  return (
    <Layout>
      <Metadata title={t(project.title)} isArticle />
      <WorkContainer>
        <CustomerLogo project={project} />
        <ProjectTitle title={t(project.title)} />
        <EnglishOnly />
        <ProjectImage data={project.gallery.main} top />
        <h2>Challenge</h2>
        <p>
          The customer, a team of biologists, needed to move from a manual
          qualitative assessment of their cell microscopy images to a
          quantitative, fully automated analysis. But this requires deep imaging
          informatics knowledge.
        </p>
        <blockquote>Biologists are not informatics experts</blockquote>
        <p>
          Automated analysis is an iterative process where configurations are
          adjusted and the analysis re-run multiple times. Imaging informatics
          experts write the initial analysis configuration, but the biologists
          still need to do the fine-tuning.
        </p>
        <blockquote>Outsourcing is slow</blockquote>
        <p>
          Conventional outsourcing is a very slow approach to this process; the
          biologists need to request a re-analysis at the provider for every
          configuration adjustment.
        </p>
        <p>
          Therefore, the biologists asked us to build an analysis automation,
          but in such a way that they can easily tune the configuration
          parameters themselves.
        </p>
        <p>
          At the core of this project were 10'000 real images which they needed
          to analyze. The analysis had to complete before the automated web
          solution could be developed.
        </p>
        <h2>Approach</h2>
        <p>
          We started the analysis of actual images and the project concept at
          the same time.
        </p>
        <blockquote>Provide analysis service and develop a concept</blockquote>
        <p>
          We subcontracted the{' '}
          <a
            href="https://www.broadinstitute.org/"
            target="_blank"
            rel="noreferrer"
          >
            Broad Institute
          </a>{' '}
          of MIT and Harvard who are also the maintainers of the{' '}
          <a href="https://cellprofiler.org/" target="_blank" rel="noreferrer">
            CellProfiler
          </a>{' '}
          software which we used for the analysis. The Broad team configured the
          analysis pipeline and then run the first analyses on the Broad{' '}
          <a
            href="https://en.wikipedia.org/wiki/Computer_cluster"
            target="_blank"
            rel="noreferrer"
          >
            computer cluster
          </a>{' '}
          as this type of analysis requires a lot of computing power. At the
          same time, we started developing the concept.
        </p>
        <blockquote>
          Create shared infrastructure to seamlessly co-develop analysis
        </blockquote>
        <p>
          First, to make the actual analysis computation available to both the
          biologists and the informatics experts, we built a web service that
          runs a cloud-based computation. The service accepts the exact analysis
          configuration, then sets up an ad-hoc cloud computer cluster for
          processing the thousands of images. The key technologies used in this
          step were <em>docker</em> for defining the cluster nodes, and Amazon{' '}
          <em>AWS Spot Instances</em> for running the cluster.
        </p>
        <p>
          In addition, we built a website where biologists and informatics
          experts interact: Biologists upload sample images, informatics experts
          upload a matching analysis configuration. Then, the website lets both
          parties trigger an analysis, and shows the resulting image
          segmentation as well as charts for the statistical evaluation of the
          results.
        </p>
        <blockquote>
          Seamless collaboration between biologists and image informatics
          experts
        </blockquote>
        <p>
          To enable a seamless collaboration between biologists and image
          informatics experts, we let the informatics experts expose analysis
          parameters that should be fine-tuned by Biologists through the
          website.
        </p>
        <p>
          Finally, the website would store all projects and have them ready for
          biologists to re-run at any time, or kickstart new projects easily.
        </p>
        <h2>Result</h2>
        <p>
          We developed a working prototype of the full system that could set up
          and run the cluster. We also developed the website that would show the
          resulting images and the statistical analysis.
        </p>
        <p>
          A key benefit of the system was that the analysis could be reproduced
          or adapted even years later, as our mechanism saved the exact version
          of everything including the CellProfiler and Computer Operating System
          versions.
        </p>
        <p>
          We developed the full concept for the remaining pieces and summarized
          them in a video.
        </p>
        <Youtube
          title="cellexpress"
          src="https://www.youtube.com/embed/BmdK23aMqF0"
        />
        <p>
          This project is currently paused as the image analysis result did not
          yield the anticipated result, leading to personnel changes.
        </p>
        <p>
          You can find more project details at{' '}
          <a href="http://www.cellexpress.io" target="_blank" rel="noreferrer">
            cellexpress.io
          </a>
        </p>
      </WorkContainer>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    projectsJson(name: { eq: "cellexpress" }) {
      name
      title
      customer
      customerLogo {
        childImageSharp {
          gatsbyImageData
        }
      }
      tags
      link
      gallery {
        main {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        cellexpress_1 {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
